<template>
  <v-col class="pa-6">
    <h2 class="font-weight-bold headline hidden-sm-and-down">신청과 후기를 관리할 수 있습니다.</h2>
    <div class="label mt-6 mb-2">조회기간 설정</div>
    <v-row>
      <v-col cols="12" md="6" class="pa-2">
        <v-btn-toggle v-model="duration" mandatory>
          <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px; height: 36px" @click="updateDate(0, 2)">
            2주
          </v-btn>
          <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px; height: 36px" @click="updateDate(1, 0)">
            1개월
          </v-btn>
          <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px; height: 36px" @click="updateDate(3, 0)">
            3개월
          </v-btn>
          <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px; height: 36px" @click="updateDate(0, 0)">
            전체
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-menu
            ref="menuStart"
            :close-on-content-click="false"
            v-model="menuStart"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                v-model="startFormatted"
                prepend-icon="event"
                class="mt-0"
                @blur="startDate = parseDate(startFormatted)"
                style="max-width: 140px">
              </v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              locale="ko-kr"
              :max="endDate"
              no-title
              scrollable
              @input="menuStart = false">
            </v-date-picker>
          </v-menu>
          <div class="pa-4 pt-6">-</div>
          <v-menu
            :close-on-content-click="false"
            v-model="menuEnd"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                v-model="endFormatted"
                prepend-icon="event"
                class="mt-0"
                @blur="endDate = parseDate(endFormatted)"
                style="max-width: 140px">
              </v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              locale="ko-kr"
              :min="startDate"
              no-title
              scrollable
              @input="menuEnd = false">
            </v-date-picker>
          </v-menu>
        </v-row>
      </v-col>
    </v-row>
    <!--------------------------------------------------->
    <div v-if="company.type === 'A'">
      <div class="body-1 font-weight-bold mt-6 mb-2">차수 정보</div>
      <div class="line"></div>
      <AdsPayment :adsPayments="filteredAdsPayments" :contracts="contracts" @complete="$emit('complete')"> </AdsPayment>
    </div>
    <!--------------------------------------------------->
    <div
      v-if="
        company.type === 'A' &&
        $moment().startOf('day').diff($moment(company.naver.payInfo_startPaidAt).startOf('day'), 'days') <
          company.periodApply + company.periodAccept + 1 &&
        !accepted
      ">
      <div class="body-1 font-weight-bold mt-6 mb-2">신청 대기</div>
      <div class="line"></div>
      <Apply
        :adsPayments="filteredAdsPayments"
        :contracts="filteredApply"
        :option="option"
        :tap="tap"
        @complete="$emit('complete')">
      </Apply>
    </div>
    <div>
      <div v-if="company.category.firstName === '제품'" class="body-1 font-weight-bold mt-6 mb-2">발송 관리</div>
      <div v-if="company.category.firstName === '제품'" class="line"></div>
      <Product
        v-if="company.category.firstName === '제품'"
        :contracts="filteredProduct"
        :option="option"
        :tap="tap"
        @complete="$emit('complete')">
      </Product>
      <div class="body-1 font-weight-bold mt-6 mb-2">리뷰 관리</div>
      <div class="line"></div>
      <Store :contracts="filtered" :tap="tap" @complete="$emit('complete')"> </Store>
    </div>
  </v-col>
</template>

<script>
import companyMixin from '@/components/shared/companyMixin';

export default {
  name: 'DirectDelivery',
  mixins: [companyMixin],
  props: ['contracts', 'adsPayments', 'option', 'tap'],
  components: {
    Apply: () => import('@/components/company/contract/Apply'),
    Store: () => import('@/components/company/contract/Store'),
    Product: () => import('@/components/company/contract/Product'),
    AdsPayment: () => import('@/components/company/contract/AdsPayment'),
  },
  data() {
    return {
      duration: 1,
      menuStart: false,
      menuEnd: false,
      startDate: '',
      endDate: '',
      startFormatted: null,
      endFormatted: null,
    };
  },
  computed: {
    filtered: function () {
      return (
        this.contracts.filter(contract => {
          contract['delivery'] = contract['delivery'] || {};
          let date = this.$moment(new Date(this.company.type === 'A' ? contract.selectedAt : contract.date0))
            .tz('Asia/Seoul')
            .format('YYYY-MM-DD');
          return (
            contract.currentState !== -2 &&
            this.$moment(date).tz('Asia/Seoul').isBetween(this.startDate, this.endDate, null, '[]')
          );
        }) || []
      );
    },
    filteredProduct: function () {
      return (
        this.contracts.filter(contract => {
          contract['delivery'] = contract['delivery'] || {};
          let date = this.$moment(new Date(contract.date0)).tz('Asia/Seoul').format('YYYY-MM-DD');
          return (
            this.$moment(date).tz('Asia/Seoul').isBetween(this.startDate, this.endDate, null, '[]') &&
            contract.currentState > -1
          );
        }) || []
      );
    },
    filteredAdsPayments: function () {
      return this.adsPayments.filter(adsPayment => !!adsPayment.nextPaidAt);
    },
    filteredApply: function () {
      return (
        this.contracts.filter(contract => {
          contract['delivery'] = contract['delivery'] || {};
          let date = this.$moment(new Date(contract.appliedAt)).tz('Asia/Seoul').format('YYYY-MM-DD');
          return (
            this.$moment(date).tz('Asia/Seoul').isBetween(this.startDate, this.endDate, null, '[]') &&
            this.$moment(date)
              .tz('Asia/Seoul')
              .isSameOrAfter(this.company.naver.payInfo_startPaidAt || this.company.naver.startDate, 'day') &&
            (contract.currentState === -2 || contract.currentState === 0)
          );
        }) || []
      );
    },
    accepted: function () {
      return (
        (
          this.contracts.filter(contract => {
            let date = this.$moment(new Date(contract.appliedAt)).tz('Asia/Seoul').format('YYYY-MM-DD');
            return (
              this.$moment(date)
                .tz('Asia/Seoul')
                .isSameOrAfter(this.company.naver.payInfo_startPaidAt || this.company.naver.startDate, 'day') &&
              (contract.currentState >= 0 || contract.currentState === -3)
            );
          }) || []
        ).length >= this.company.naver.number
      );
    },
  },
  watch: {
    startDate() {
      this.startFormatted = this.formatDate(this.startDate);
    },
    endDate() {
      this.endFormatted = this.formatDate(this.endDate);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${year}. ${month}. ${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('. ');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    updateDate(mon, week) {
      this.endDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
      if (mon > 0 || week > 0) {
        this.startDate = this.$moment().subtract(mon, 'months').subtract(week, 'weeks').format('YYYY-MM-DD');
      } else {
        this.startDate = this.$moment().subtract(10, 'years').format('YYYY-MM-DD');
      }
    },
  },
  created() {
    this.startDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
    this.endDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');

    this.startFormatted = this.formatDate(this.startDate);
    this.endFormatted = this.formatDate(this.endDate);

    this.updateDate(1, 0);
  },
};
</script>

<style scoped lang="scss">
::v-deep .line {
  border-bottom: 1px solid #757575;
  border-bottom: 1px solid var(--warm-grey-color);
  width: 100%;
}

::v-deep .line2 {
  border-bottom: 1px solid #f5f5f5;
  border-bottom: 1px solid var(--white-color);
  width: 100%;
}

::v-deep .v-datatable.v-table {
  background-color: transparent;
  ::v-deep .v-datatable__actions {
    background-color: transparent;
  }
}

::v-deep .row_content {
  color: #bd1328;
  color: var(--rouge-color);
  letter-spacing: -0.9px;
  text-decoration: underline;
}
</style>
